import type { InputProps } from '@chakra-ui/react';
import { Input, Text } from '@chakra-ui/react';
import { forwardRef, type FC } from 'react';

interface TextInputProps extends InputProps {
  label?: string;
}

export const TextInput: FC<TextInputProps> = forwardRef(
  ({ label, ...rest }, ref) => {
    return (
      <>
        {label && (
          <Text fontSize="sm" color="primary" mb="8px">
            {label}
          </Text>
        )}
        <Input
          ref={ref}
          w="100%"
          h="56px"
          mb="16px"
          p="16px"
          color="primary"
          background="primaryAlpha.50"
          border="1px solid"
          borderColor="primaryAlpha.100"
          borderRadius="8px"
          _focusVisible={{
            borderColor: 'green.500',
          }}
          _placeholder={{ color: 'primaryAlpha.400' }}
          {...rest}
        />
      </>
    );
  },
);

TextInput.displayName = 'TextInput';
